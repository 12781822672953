<template>
  <b-container>
    <b-row>
      <b-col>
        <Heading type="h1" :title="trans('global.styletypes')" :button="{ title: trans('prospects.styles.add_new'), modal: 'add-style-modal' }" />
      </b-col>
    </b-row>
    <b-row v-if="numOfStyles < 1">
      <b-col>
        <div class="alert alert-warning">
          <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('prospects.styles.no_styles') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div v-if="!styleTypes" class="loading"></div>
        <div v-else>
          <b-row>
            <b-col v-for="style in styleTypes" :key="style.id" sm="12">
              <b-card class="margin-top-16">
                <b-row>
                  <b-col class="col-sm-auto">
                    <b-img v-if="style.image" :src=" cdnUrl + '/' + style.image.path + '/' + style.image.file + '?fit=crop&w=81&h=54&mask=corners&corner-radius=5,5,5,5'"></b-img>
                    <div v-else style="background-color: green; opacity: 0.2; width: 81px; height: 54px; border-radius: 4px;"></div>
                  </b-col>
                  <b-col>
                    <span>{{ style.name }}</span>
                  </b-col>
                  <b-col>
                    <span>{{ style.description | truncate(100) }}</span>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Heading from '../../../../Core/Heading'

  export default {
    name: 'ProjectSalesProspectStyles',
    components: {
      Heading
    },
    props: {
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        cdnUrl: process.env.VUE_APP_IMAGE_CDN_URL
      }
    },
    computed: {
      numOfStyles () {
        return this.prospect.styleTypes !== undefined ? Object.keys(this.prospect.styleTypes).length : 0
      },
      styleTypes () {
        return this.prospect.styleTypes !== undefined && Object.keys(this.prospect.styleTypes).length > 0 ? this.prospect.styleTypes : false
      }
    }
  }
</script>
